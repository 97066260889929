* {
  box-sizing: border-box;
}
h2 {
  margin: 16px 0;
  display: inline-block;
}

.balances {
  font-family: "Lucida Console",Monaco,monospace;
}

.font-weight-bold {
  font-weight: bold;
}

.btn {
  border: 2px solid #0072CE;
  padding: 8px;
  height: 32px;
  background: white;
  border-radius: 16px;
  margin: 8px 16px 8px 0;
  line-height: 12px;
  cursor: pointer;
  text-decoration: none;
  color: black;
  &:disabled {
    border-color: #ccc !important;;
    background: white !important;
    color: #ccc !important;
  }
  &:focus {
    outline: none;
  }
  &:hover {
    background: #ccddff;
  }
  &:active {
    color: white;
    background: #0072CE;
  }
}

.btn-success {
  border: 2px solid #00CE33;
  &:hover {
    background: #7e7;
  }
}

.btn-outline-none {
  border: none;
  margin-left: 0.5em;
}

.btn-outline-secondary {
  margin-top: 1.5em;
  border-color: #eee;
}

.padded {
  padding: 16px;
}

.video-container {
  overflow: hidden;
  position: relative;
  margin: 0 auto;
  width:100%;
  max-width: 800px;
}

.video-container::after {
  padding-top: 56.25%;
  display: block;
  content: '';
}

.video-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}


.header {
  padding: 16px 16px 0px 16px;
}

.container {
  padding: 0px 16px;
  display: flex;
  justify-content: center;
}

.react-switch {
  vertical-align: middle;
}

.berry {
}

.switch-berry {
  font-size: 1.6em;
  text-align: center;
}

.leaderboard {
  max-width: 380px;
  font-size: 0.9em;
  a {
    color: inherit;
    text-decoration: none;
    &:hover, &:active {
      color: #0072CE;
      text-decoration: underline;
    }
  }
  tr {
    height: 1em;
    display: inline-table;
    width: 100%;
    border-bottom: 1px solid #ddd;
    .text-nowrap {
      text-align: right;
    }
    &:hover {
      background: #ddd;
    }
  }
  .selected {
    background: #ddd;
  }
}

.row {
  display: flex;
  justify-content: center;
  width: 100%;
  > div:not(:first-child) {
    padding-left: 16px;
  }
}

@mixin block {
  width: 100%;
  max-width: 600px;
  margin-bottom: 16px;
}

.pixel-board {
  @include block;
  border: 1px solid #ddd;
  &.watch-mode {
    transition: 3s;
    position: fixed;
    width: calc(min(100vh, 100vw));
    max-width: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border: 1px solid #000;
    margin: 0 !important;
    padding: 0 !important;
  }
}



.hud {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  > p {
    display: block;
    min-width: 100px;
    margin: 8px 0;
  }
}

.float-right {
  position: absolute;
  top: 0;
  right: 0;
}

.color-picker {
  > div {
    border-radius: 0 !important;
    box-shadow: none !important;
    padding: 0 !important;
    border: none !important;
    margin-bottom: 16px;
    width: 100%;
    max-width: 800px;
  }
}

.hidden {
  display: none;
}

.circle-picker {
  margin-top: 5px;
}

.irc {
  width: 100%;
  height: 500px;
}


@keyframes placeHolderShimmer {
  0% {
    background-position: 0px 0;
  }
  100% {
    background-position: 0 600px;
  }
}

.c-animated-background {
  animation-duration: 2s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background: #333;
  background: linear-gradient(to bottom, #333 0%, #116611 4%, #55ff55 8%, #116611 12%, #333 16%);
}

.call-to-action {
  font-size: 3em;
  .btn {
    margin-right: 0em;
  }
}


/********************************
Layout For Tablet / Phone
********************************/

@media (max-width: 1000px) {
  .row {
    flex-direction: column;
    max-width: 600px;
    div {
      padding-left: 0 !important;
    }
  }
}

@media (max-width: 600px) {
  .pixel-board {
    width: 100vw;
    margin-left: -16px;
  }
  h2 {
    text-align: left;
  }
}

@media (max-width: 425px) {
  .float-right {
    position: relative !important;
  }
  .btn-outline-secondary {
    margin-top: 0;
  }
  .call-to-action {
    font-size: 1.6em;
  }
}

.root-modal {

}
.free-drawing {
  font-size: 1.5em;
  margin-bottom: 0.5em;
  &.free {
    color: #ffff00;
    background: #000;
  }
  &.wait {
    color: #999999;
  }
}
